import { ReactNode } from 'react';
import theme from 'styles/theme';
import { ProfileReportStats } from 'types/patient';

const colors = {
  red: theme.palette.error.main,
  green: theme.palette.secondary.main,
  yellow: '#FCC214',
  purple: '#531CB3',
  darkBlue: theme.palette.primary.main,
};

export type ReportWidgetType = 'concurrent' | 'list' | 'simple';

type ReportWidgetItem = {
  name: string;
  color: string;
  value: number;
  tooltip?: string;
};

export type ReportWidgetBodyItem =
  | {
      type: 'concurrent';
      items: ReportWidgetItem[];
    }
  | {
      type: 'simple';
      title: string;
      value: string;
      tooltip?: string;
    }
  | {
      type: 'list';
      subTitle?: string;
      items: ReportWidgetItem[];
      transformValue?: (value: number) => string | number;
      transformTotal?: (value: number) => ReactNode;
      hideTotal?: boolean;
      customTotal?: number;
    };

type ReportWidget = {
  title: string;
  body: ReportWidgetBodyItem[];
};

const formatAlertEvents = (
  events: ProfileReportStats['top_alerter_events_count'],
) => {
  const localColors = [
    colors.purple,
    colors.red,
    colors.green,
    colors.yellow,
    colors.darkBlue,
  ];
  return Object.entries(events).map(([key, value], index) => ({
    name: key.replace(/_/g, ' '),
    value,
    color: localColors[index],
  }));
};

const secondsToString = (seconds: number) => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor(seconds / 3600) - days * 24;

  if (days > 0) {
    return `${days}d` + (hours > 0 ? ` ${hours}h` : '');
  }

  const minutes = Math.floor((seconds % 3600) / 60) - hours * 60;

  if (hours > 0) {
    return `${hours}h` + (minutes > 0 ? ` ${minutes}m` : '');
  }

  const remainingSeconds = Math.ceil(seconds % 60) - minutes * 60;

  if (minutes > 0) {
    return (
      `${minutes}m` + (remainingSeconds > 0 ? ` ${remainingSeconds}s` : '')
    );
  }

  return `${remainingSeconds}s`;
};

export const getReportsWidgets = (report?: ProfileReportStats) => {
  if (!report) {
    return [];
  }

  const widgets: ReportWidget[] = [];

  widgets.push({
    title: 'Online Status',
    body: [
      {
        type: 'concurrent',
        items: [
          {
            name: 'Connected',
            color: colors.green,
            value: report.seconds_spent_connected,
          },
          {
            name: 'Disconnected',
            color: colors.red,
            value: report.seconds_spent_disconnected,
          },
        ],
      },
      {
        type: 'list',
        subTitle: 'Time spent disconnected',
        transformValue: secondsToString,
        items: [
          {
            name: 'No network',
            color: colors.red,
            value:
              report.seconds_spent_disconnected_without_network_connectivity,
          },
          {
            name: 'Lost power',
            color: colors.green,
            value: report.seconds_spent_disconnected_after_discharge,
          },
          {
            name: 'Other',
            color: colors.yellow,
            value: report.seconds_spent_disconnected_for_other_reasons,
          },
        ],
      },
    ],
  });

  widgets.push({
    title: 'Watch Usage',
    body: [
      {
        type: 'concurrent',
        items: [
          {
            color: colors.green,
            name: 'Used',
            value: report.seconds_spent_used,
          },
          {
            color: colors.red,
            name: 'Unused',
            value: report.seconds_spent_unused,
          },
        ],
      },
      {
        type: 'list',
        subTitle: 'Time spent unused',
        transformValue: secondsToString,
        items: [
          {
            color: colors.green,
            name: 'On charger',
            value: report.seconds_spent_on_charger,
          },
          {
            color: colors.red,
            name: 'Disconnected/Other',
            value: report.seconds_spent_not_reporting_excluding_discharge,
          },
          {
            color: colors.yellow,
            name: 'Lost Power',
            value: report.seconds_spent_disconnected_after_discharge,
          },
        ],
      },
    ],
  });

  widgets.push({
    title: 'Battery Power Usage',
    body: [
      {
        type: 'simple',
        title: 'Average Battery Life',
        value: !report.average_projected_battery_life_in_seconds
          ? 'N/A'
          : secondsToString(report.average_projected_battery_life_in_seconds),
      },
      {
        type: 'list',
        subTitle: 'Usage by Connectivity',
        transformValue: secondsToString,
        hideTotal: true,
        items: [
          {
            color: colors.red,
            name: 'Cellular',
            value: report.seconds_spent_on_cellular,
          },
          {
            color: colors.green,
            name: 'WiFi/Bluetooth',
            value: report.seconds_spent_on_wifi_or_bluetooth,
          },
        ],
      },
      {
        type: 'simple',
        title: 'Average Time Between Data Updates',
        value: secondsToString(
          report.average_time_between_record_posts_in_seconds,
        ),
      },
    ],
  });

  widgets.push({
    title: 'Alerts Acknowledgements',
    body: [
      {
        type: 'concurrent',
        items: [
          {
            color: colors.green,
            name: 'Acknowledged',
            value: report.acknowledged_alerter_events_count,
          },
          {
            color: colors.red,
            name: 'Pending',
            value: report.alerter_events_pending_required_acknowledgement_count,
          },
        ],
      },
      {
        type: 'simple',
        title: 'Average Time to Acknowledge',
        value: !report.acknowledged_alerter_events_count
          ? 'N/A'
          : secondsToString(
              report.average_seconds_to_acknowledge_alerter_event,
            ),
      },
    ],
  });

  widgets.push({
    title: 'Data Integrity',
    body: [
      {
        type: 'concurrent',
        items: [
          {
            name: 'Complete',
            value: report.records_off_charger_count,
            color: colors.green,
          },
          {
            name: 'Incomplete',
            value: report.records_off_charger_with_an_issue_count,
            color: colors.red,
          },
        ],
      },
      {
        type: 'list',
        subTitle: 'Records with Issues',
        hideTotal: true,
        customTotal:
          report.records_off_charger_with_an_issue_count +
          report.records_off_charger_count,
        items: [
          {
            name: 'Posting Delays',
            value: report.records_with_delayed_posting_count,
            color: colors.purple,
          },
          {
            name: 'Missing Health Data',
            value: report.records_unexpectedly_lacking_health_count,
            color: colors.red,
          },
          {
            name: 'Location Delays',
            value: report.records_with_delayed_location_data_count,
            color: colors.yellow,
          },
          {
            name: 'Heart Rate Delays',
            value: report.records_with_delayed_heart_rate_data_count,
            color: colors.green,
          },
          {
            name: 'Missing Location Data',
            value: report.records_unexpectedly_lacking_location_count,
            color: colors.darkBlue,
          },
        ],
      },
    ],
  });

  const topAlerts = formatAlertEvents(report.top_alerter_events_count ?? {});

  widgets.push({
    title: 'Top Alerts',
    body: [
      {
        type: 'list',
        subTitle: 'Total Alerts Detected',
        items: topAlerts,
      },
    ],
  });

  widgets.push({
    title: 'Top High-Priority Alerts',
    body: [
      {
        type: 'list',
        subTitle: 'High-Priority Alerts Detected',
        items: [
          {
            name: 'Fall Detected',
            value: report.alerter_events_count.fall,
            color: colors.red,
          },
          {
            name: 'Seizure Detection',
            value: report.alerter_events_count.motion_disorder,
            color: colors.yellow,
          },
          {
            name: 'Help Requested',
            value: report.alerter_events_count.help_requested,
            color: colors.green,
          },
          {
            name: 'Safe Zone Crossing',
            value: report.alerter_events_count.safe_zone,
            color: colors.darkBlue,
          },
        ],
      },
    ],
  });

  return widgets;
};
