import { Box, Button, Grid2, Typography } from '@mui/material';
import VButton from 'components/VButton/VButton';
import { IDashboardResult } from 'models/dashboard.model';
import dayjs from 'dayjs';
import React, { MouseEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'styles/theme';
import DatePicker from 'react-datepicker';

import * as Styled from './ProfileLookupResults.styles';
import 'react-datepicker/dist/react-datepicker.css';
import DeviceInfoModal from './DeviceInfoModal';
import { routes } from 'nav';
import PaymentSubscription from './PaymentSubscription';
import PollDevices from './PollDevices';
import PasswordReset from './PasswordReset';
import PhoneNumber from './PhoneNumber';
import AssociatedAccounts from './AssociatedAccounts';
import MinimapModal from './MinimapModal';
import EmailAddress from './EmailAddress';
import { LogOffButton } from './LogOffButton';

interface Props {
  profileId: number;
  isLoading?: boolean;
  isLovedOne?: boolean;

  profileInfo: {
    onSavePress: () => void;
    isLoading?: boolean;
    isReady?: boolean;
    firstName: {
      value: string;
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };
    lastName: {
      value: string;
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };
    uniquePublicIdentifier: {
      value?: string;
    };
    thirdPartyPublicIdentifier: {
      value?: string;
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };
    emergencyTracking: {
      value?: string;
    };
    enhancedAlerts: {
      value: boolean;
      onChange: MouseEventHandler<HTMLInputElement>;
    };
    powerSavingMode: {
      value: boolean;
      onChange: MouseEventHandler<HTMLInputElement>;
    };
    lastPollAt: {
      value: string;
    };
    collectedHealthData: {
      value: boolean;
      onChange: MouseEventHandler<HTMLInputElement>;
    };
    lastIrregularHealthAck: {
      value: Date | null;
      onChange: (date: string | null) => void;
    };
    lastHighHealthAck: {
      value: Date | null;
      onChange: (date: string | null) => void;
    };
    lastLowHealthAck: {
      value: Date | null;
      onChange: (date: string | null) => void;
    };
    lastFallAck: {
      value: Date | null;
      onChange: (date: string | null) => void;
    };
    localReminderNotifications: {
      value: boolean;
      onChange: MouseEventHandler<HTMLInputElement>;
    };
    watchChargingReminder: {
      value: string;
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };
    performanceMode: {
      value: boolean;
      onChange: MouseEventHandler<HTMLInputElement>;
    };
    debugLogging: {
      value: boolean;
      onChange: MouseEventHandler<HTMLInputElement>;
    };
    didCompleteManagedOnboarding: {
      value?: boolean;
      onChange: MouseEventHandler<HTMLInputElement>;
    };
  };
  recordInfo?: IDashboardResult | null;
  isRecordLoading?: boolean;
  recordDate: {
    value?: string;
    onChange: (date: string | null) => void;
    onAccept: () => void;
  };
  onPreviousPress: () => void;
  onNextPress: () => void;
}

const isoDateToString = (date?: string) =>
  dayjs(date).format('MMMM DD YYYY, hh:mm:ss').toString();

const ProfileLookupResultsView = (props: Props) => {
  const navigate = useNavigate();

  const [isDeviceModalOpen, setDeviceModalOpen] = useState(false);
  const [isMinimapModalOpen, setMinimapModalOpen] = useState(false);
  const [isAssociatedAccsModalOpen, setAssociatedAccsModalOpen] =
    useState(false);

  const handleDeviceModalOpen = () => {
    if (props.recordInfo?.device_id) {
      setDeviceModalOpen(true);
    }
  };

  const handleNavigateToDevices = () => {
    if (props.recordInfo?.device_id) {
      navigate(
        routes.admin.manageDevices.href +
          '?searchBy=account_id&value=' +
          props.profileId,
      );
    }
  };

  const handleAssociatedAccsModalOpen = () => {
    setAssociatedAccsModalOpen(true);
  };

  return !props.isLoading ? (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Typography variant="h5">Profile Information</Typography>
        <Box display="flex">
          {props.isLovedOne && (
            <VButton
              width="180"
              text="Go to Dashboard"
              style={{ marginRight: theme.spacing(3) }}
              onClick={() =>
                navigate(routes.private.dashboard.href(props.profileId))
              }
              type="button"
              bgcolor={theme.palette.secondary.main}
            />
          )}
          <VButton
            width="180"
            text="Clear Selection"
            onClick={() => navigate(routes.admin.lookup.href)}
            type="button"
            bgcolor={theme.palette.secondary.main}
          />
        </Box>
      </div>
      {props.profileInfo.isReady ? (
        <Styled.Wrapper>
          <Styled.LoadingOverlay isLoading={props.profileInfo.isLoading} />
          <Grid2 container columnSpacing={2} maxWidth={1200}>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Profile ID</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Typography>{props.profileId}</Typography>
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>First Name</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Input
                  value={props.profileInfo.firstName.value}
                  onChange={props.profileInfo.firstName.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Last Name</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Input
                  value={props.profileInfo.lastName.value}
                  onChange={props.profileInfo.lastName.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>BoundaryCare Identifier</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Typography>
                  {props.profileInfo.uniquePublicIdentifier.value}
                </Typography>
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Third Party Public Identifier</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Input
                  value={props.profileInfo.thirdPartyPublicIdentifier.value}
                  onChange={
                    props.profileInfo.thirdPartyPublicIdentifier.onChange
                  }
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Emergency Tracking</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Typography>
                  {isoDateToString(props.profileInfo.emergencyTracking.value)}
                </Typography>
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Enhanced Alerts</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Checkbox
                  value={props.profileInfo.enhancedAlerts.value}
                  onChangeValue={props.profileInfo.enhancedAlerts.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Power Saving Mode</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Checkbox
                  value={props.profileInfo.powerSavingMode.value}
                  onChangeValue={props.profileInfo.powerSavingMode.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Last Poll At</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Typography>
                  {isoDateToString(props.profileInfo.lastPollAt.value)}
                </Typography>
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Collected health data</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Checkbox
                  value={props.profileInfo.collectedHealthData.value}
                  onChangeValue={props.profileInfo.collectedHealthData.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Last Irregular Heart Ack</Typography>
              </Grid2>
              <Grid2 size={6}>
                <DatePicker
                  selected={props.profileInfo.lastIrregularHealthAck.value}
                  onChange={(date) =>
                    props.profileInfo.lastIrregularHealthAck.onChange(
                      date ? date.toISOString() : null,
                    )
                  }
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy hh:mm aa"
                  showTimeInput
                  customInput={<Styled.TextField size="small" margin="dense" />}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Last High Heart Ack</Typography>
              </Grid2>
              <Grid2 size={6}>
                <DatePicker
                  selected={props.profileInfo.lastHighHealthAck.value}
                  onChange={(date) =>
                    props.profileInfo.lastHighHealthAck.onChange(
                      date ? date.toISOString() : null,
                    )
                  }
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy hh:mm aa"
                  showTimeInput
                  customInput={<Styled.TextField size="small" margin="dense" />}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Last Low Heart Ack</Typography>
              </Grid2>
              <Grid2 size={6}>
                <DatePicker
                  selected={props.profileInfo.lastLowHealthAck.value}
                  onChange={(date) =>
                    props.profileInfo.lastLowHealthAck.onChange(
                      date ? date.toISOString() : null,
                    )
                  }
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy hh:mm aa"
                  showTimeInput
                  customInput={<Styled.TextField size="small" margin="dense" />}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Last Fall Ack</Typography>
              </Grid2>
              <Grid2 size={6}>
                <DatePicker
                  selected={props.profileInfo.lastFallAck.value}
                  onChange={(date) =>
                    props.profileInfo.lastFallAck.onChange(
                      date ? date.toISOString() : null,
                    )
                  }
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<Styled.TextField size="small" margin="dense" />}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Local reminder notifications</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Checkbox
                  value={props.profileInfo.localReminderNotifications.value}
                  onChangeValue={
                    props.profileInfo.localReminderNotifications.onChange
                  }
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Watch charging reminder</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Input
                  value={props.profileInfo.watchChargingReminder.value}
                  onChange={props.profileInfo.watchChargingReminder.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Performance mode</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Checkbox
                  value={props.profileInfo.performanceMode.value}
                  onChangeValue={props.profileInfo.performanceMode.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>Debug logging</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Styled.Checkbox
                  value={props.profileInfo.debugLogging.value}
                  onChangeValue={props.profileInfo.debugLogging.onChange}
                />
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={12}>
                <Button variant="text" onClick={handleNavigateToDevices}>
                  See profile devices
                </Button>
              </Grid2>
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <Grid2 size={6}>
                <Typography>
                  View associated{' '}
                  {props.isLovedOne ? 'caregivers' : 'loved ones'}
                </Typography>
              </Grid2>
              <Grid2 size={6}>
                <Button
                  variant="contained"
                  onClick={handleAssociatedAccsModalOpen}
                >
                  Open
                </Button>
              </Grid2>
            </Styled.AccountInfoGridItem>
            {props.isLovedOne && (
              <Grid2
                size={12}
                display="flex"
                gap={1.5}
                alignItems="center"
                py={2}
              >
                Did Complete Managed Onboarding
                <Styled.Checkbox
                  value={props.profileInfo.didCompleteManagedOnboarding.value}
                  onChangeValue={
                    props.profileInfo.didCompleteManagedOnboarding.onChange
                  }
                />
              </Grid2>
            )}
            <Styled.AccountInfoGridItem>
              <PaymentSubscription profileId={props.profileId} />
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <PollDevices profileId={props.profileId} />
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <PhoneNumber
                isLovedOne={props.isLovedOne}
                profileId={props.profileId}
              />
            </Styled.AccountInfoGridItem>
            <Styled.AccountInfoGridItem>
              <EmailAddress profileId={props.profileId} />
            </Styled.AccountInfoGridItem>
            {props.isLovedOne === false && (
              <Styled.AccountInfoGridItem>
                <PasswordReset accountId={props.profileId} />
              </Styled.AccountInfoGridItem>
            )}
            <Styled.AccountInfoGridItem>
              <Typography variant="h6" my={1}>
                Expire Sessions
              </Typography>
              <LogOffButton accountId={props.profileId} />
            </Styled.AccountInfoGridItem>
          </Grid2>
          <Box marginTop={2} display="flex" justifyContent="end">
            <VButton
              width={200}
              text="Save changes"
              type="button"
              onClick={props.profileInfo.onSavePress}
              bgcolor={theme.palette.secondary.main}
            />
          </Box>
        </Styled.Wrapper>
      ) : (
        <Styled.Wrapper />
      )}
      {props.recordInfo && props.recordInfo.account_id === props.profileId ? (
        <React.Fragment>
          <Typography variant="h5" mt={4}>
            Record Information
          </Typography>
          <Styled.Wrapper>
            <Styled.LoadingOverlay isLoading={props.isRecordLoading} />
            <Styled.TimePickerWrapper>
              <VButton
                width={160}
                text="< Previous"
                type="button"
                onClick={props.onPreviousPress}
                bgcolor={theme.palette.secondary.main}
              />
              <Styled.TimeInputWrapper>
                <Typography marginRight={2}>Near</Typography>
                <DatePicker
                  selected={
                    props.recordDate.value
                      ? new Date(props.recordDate.value)
                      : null
                  }
                  onChange={(date) =>
                    props.recordDate.onChange(date?.toISOString() || null)
                  }
                  onCalendarClose={props.recordDate.onAccept}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<Styled.TextField size="small" margin="dense" />}
                />
              </Styled.TimeInputWrapper>
              <VButton
                width={160}
                text="Next >"
                type="button"
                onClick={props.onNextPress}
                bgcolor={theme.palette.secondary.main}
              />
            </Styled.TimePickerWrapper>
            <Grid2 container columnSpacing={2} rowSpacing={1} maxWidth={1200}>
              <Styled.RecordsInfoCategory>
                Record Details
              </Styled.RecordsInfoCategory>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Record ID</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>{props.recordInfo.id}</Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Device ID</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {props.recordInfo.device_id}{' '}
                    <Button
                      variant="text"
                      onClick={handleDeviceModalOpen}
                      sx={{ height: 2, padding: 2 }}
                    >
                      View details
                    </Button>
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Generated At</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {isoDateToString(props.recordInfo.generated_at)}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Created At</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {isoDateToString(props.recordInfo.created_at)}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Source UUID</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>{props.recordInfo.source_uuid}</Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>

              {props.recordInfo.location_record?.record_data && (
                <>
                  <Styled.RecordsInfoCategory>
                    Location Record
                    <Button
                      size="small"
                      sx={{ ml: ({ spacing }) => spacing(2) }}
                      onClick={() => setMinimapModalOpen(true)}
                    >
                      Preview
                    </Button>
                  </Styled.RecordsInfoCategory>
                  <Styled.RecordsInfoGridItem>
                    <Grid2 size={6}>
                      <Typography>Latitude</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                      <Typography>
                        {
                          props.recordInfo.location_record.record_data
                            .primary_location.lat
                        }
                      </Typography>
                    </Grid2>
                  </Styled.RecordsInfoGridItem>
                  <Styled.RecordsInfoGridItem>
                    <Grid2 size={6}>
                      <Typography>Longitude</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                      <Typography>
                        {
                          props.recordInfo.location_record.record_data
                            .primary_location.lng
                        }
                      </Typography>
                    </Grid2>
                  </Styled.RecordsInfoGridItem>
                  <Styled.RecordsInfoGridItem>
                    <Grid2 size={6}>
                      <Typography>Accuracy</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                      <Typography>
                        {
                          props.recordInfo.location_record.record_data
                            .primary_location.accuracy
                        }
                      </Typography>
                    </Grid2>
                  </Styled.RecordsInfoGridItem>
                  <Styled.RecordsInfoGridItem>
                    <Grid2 size={6}>
                      <Typography>Timestamp</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                      <Typography>
                        {isoDateToString(
                          props.recordInfo.location_record.record_data
                            .primary_location.timestamp,
                        )}
                      </Typography>
                    </Grid2>
                  </Styled.RecordsInfoGridItem>
                  <Styled.RecordsInfoGridItem>
                    <Grid2 size={6}>
                      <Typography>Bad Location Latitude</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                      <Typography>
                        {String(
                          props.recordInfo.location_record.record_data
                            .bad_location_latitude,
                        )}
                      </Typography>
                    </Grid2>
                  </Styled.RecordsInfoGridItem>
                </>
              )}

              <Styled.RecordsInfoCategory>
                Device Info Record
              </Styled.RecordsInfoCategory>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Device Name</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {
                      props.recordInfo.device_info_record.record_data
                        .device_name
                    }
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Device Locked</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.device_info_record.record_data
                        .device_locked,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Trigger</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {props.recordInfo.device_info_record.record_data.trigger}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Background Networking</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.device_info_record.record_data
                        .background_networking,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Workout Session State</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {
                      props.recordInfo.device_info_record.record_data
                        .workout_session_state
                    }
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Has Valid Auth Token</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {props.recordInfo.device_info_record.record_data.has_valid_auth_token.toString()}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography pr={1}>Extended Runtime Session State</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {props.recordInfo.device_info_record.record_data
                      .extended_runtime_session_state ?? 'N/A'}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Has Valid Notification Token</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.device_info_record.record_data
                        .has_valid_notification_token,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Battery Level</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {
                      props.recordInfo.device_info_record.record_data
                        .battery_level
                    }
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Battery State</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {
                      props.recordInfo.device_info_record.record_data
                        .battery_state
                    }
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Running State</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {
                      props.recordInfo.device_info_record.record_data
                        .running_state
                    }
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Active Complication</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.device_info_record.record_data
                        .active_complication,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Water Lock Enabled</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.device_info_record.record_data
                        .water_lock_enabled,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={3}>
                  <Typography>Cell Network Information</Typography>
                </Grid2>
                <Grid2 size={9}>
                  <Styled.JsonWrapper
                    src={
                      props.recordInfo.device_info_record.record_data
                        .cell_network_information
                    }
                  />
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={3}>
                  <Typography>Location Permissions</Typography>
                </Grid2>
                <Grid2 size={9}>
                  <Styled.JsonWrapper
                    src={
                      props.recordInfo.device_info_record.record_data
                        .permissions.location_permissions
                    }
                  />
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={3}>
                  <Typography>Notification Permissions</Typography>
                </Grid2>
                <Grid2 size={9}>
                  <Styled.JsonWrapper
                    src={
                      props.recordInfo.device_info_record.record_data
                        .permissions.notifications_permissions
                    }
                  />
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={3}>
                  <Typography>Fall Detection Permissions</Typography>
                </Grid2>
                <Grid2 size={9}>
                  <Styled.JsonWrapper
                    src={
                      props.recordInfo.device_info_record.record_data
                        .permissions.fall_detection_permissions
                    }
                  />
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={3}>
                  <Typography>Motion Data Permissions</Typography>
                </Grid2>
                <Grid2 size={9}>
                  <Styled.JsonWrapper
                    src={
                      props.recordInfo.device_info_record.record_data
                        .permissions.motion_data_permissions
                    }
                  />
                </Grid2>
              </Styled.RecordsInfoGridItem>

              <Styled.RecordsInfoCategory>
                Settings Record
              </Styled.RecordsInfoCategory>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Device Active</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.settings_record.record_data
                        .device_active,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Any Device Active</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.settings_record.record_data
                        .any_device_active,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Configuration OK</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.settings_record.record_data
                        .configuration_ok,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Active Compliance Subscriptions</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.settings_record.record_data
                        .account_has_active_compliance_subscriptions,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Active Fall Subscriptions</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.settings_record.record_data
                        .account_has_active_fall_subscriptions,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>In Zone</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.settings_record.record_data.lo_in_zone,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Account Last Poll At</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {isoDateToString(
                      props.recordInfo.settings_record.record_data.last_poll_at,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Device has Bad Location Latitude</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {String(
                      props.recordInfo.settings_record.record_data
                        .device_has_bad_location_latitude,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
              <Styled.RecordsInfoGridItem>
                <Grid2 size={6}>
                  <Typography>Device Last Disconnected At</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography>
                    {isoDateToString(
                      props.recordInfo.settings_record.record_data
                        .device_last_disconnected_at,
                    )}
                  </Typography>
                </Grid2>
              </Styled.RecordsInfoGridItem>
            </Grid2>
          </Styled.Wrapper>
          <DeviceInfoModal
            deviceId={props.recordInfo.device_id}
            isOpen={isDeviceModalOpen}
            onClose={() => setDeviceModalOpen(false)}
          />
          <MinimapModal
            isOpen={isMinimapModalOpen}
            onClose={() => setMinimapModalOpen(false)}
            location={
              props.recordInfo?.location_record?.record_data?.primary_location
            }
          />
        </React.Fragment>
      ) : null}
      <AssociatedAccounts
        accountId={props.profileId}
        isLovedOne={props.isLovedOne}
        isOpen={isAssociatedAccsModalOpen}
        onClose={() => setAssociatedAccsModalOpen(false)}
      />
    </React.Fragment>
  ) : (
    <div />
  );
};

export default ProfileLookupResultsView;
