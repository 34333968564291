import { DateRangeInput } from 'components/DateRangeInput';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { setDateRange } from 'services/alert-history-filters';
import { DateRange } from 'types/date-time';

type Props = {
  defaultRange?: DateRange;
  onChange: (newValue: DateRange) => void;
};

export const DateRangePicker = ({ onChange, defaultRange }: Props) => {
  const handleDateRangeChange = (newValue: DateRange) => {
    onChange(newValue);
  };

  useEffect(() => {
    setDateRange([dayjs().subtract(7, 'days'), dayjs()]);
  }, []);

  return (
    <DateRangeInput
      size="small"
      labelPlacement="top"
      disableFuture
      onChange={handleDateRangeChange}
      defaultRange={defaultRange ?? [dayjs().subtract(7, 'days'), dayjs()]}
    />
  );
};
