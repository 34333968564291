import { alpha, Box, Typography } from '@mui/material';

type Props = {
  title: string;
  value: string;
};

export const SimpleView = ({ title, value }: Props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        sx={{ color: alpha('#000', 0.6), fontSize: 14, lineHeight: '20px' }}
      >
        {title}
      </Typography>
      <Typography sx={{ fontSize: 24, lineHeight: '32px' }}>{value}</Typography>
    </Box>
  );
};
