/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import MapView from './Map.view';

import theme from 'styles/theme';
import { Point, Region } from 'utils/polygon-tools';
import { LocationStats } from 'types/patient';
import { TrackingButtonProps } from './TrackingButton';
import { FormattedRegions, SafeZone } from 'types/safe-zones';
import { initMap } from 'utils/map';

export const getPixelsFromZoomLevelAndRadius = (
  zoomLevel: number,
  radius: number,
) => {
  // This number was derived from doing manual calculations using the pixels on the
  // screen. This needs to be replaced in the future with the correct calculation.
  const magicNumber = 0.00000848770145;

  return magicNumber * Math.pow(2, zoomLevel + 1) * radius;
};

export function handleMapZoomEvent(e: mapkit.EventBase<mapkit.Map>) {
  const zoomLevel = (e.target as any)?._impl?.zoomLevel || 18;
  const polylineOverlays: any[] =
    (e.target as any)?._impl?._overlaysController?._items?.filter(
      (item: any) => !!item._impl._data?.radius,
    ) || [];

  polylineOverlays.forEach((item) => {
    // mapkit has incorrect types for this implementation
    item._impl._style = new (mapkit as any).Style({
      strokeColor: theme.palette.secondary.main,
      lineWidth: getPixelsFromZoomLevelAndRadius(
        zoomLevel,
        item._impl._data.radius,
      ),
      strokeOpacity: 0.3,
    });
  });
}

type Props = {
  patientName?: string;
  location?: LocationStats;
  regions?: FormattedRegions;
  height?: number | string;
  width?: number | string;
  disabled?: boolean;
  isLoading?: boolean;
  isRegionsLoading?: boolean;
  trackingButtonProps?: TrackingButtonProps;
  hideBcControls?: boolean;
  dontHighlightLocation?: boolean;
};

let map: mapkit.Map | null = null;

const Map = ({
  regions = {
    circleRegions: [],
    pathRegions: [],
    polygonRegions: [],
  },
  ...props
}: Props) => {
  const mapkit = window.mapkit;

  const mapCoordonate = [props.location?.lat || 0, props.location?.lng || 0];

  const center = new mapkit.Coordinate(mapCoordonate[0], mapCoordonate[1]);
  const span = new mapkit.CoordinateSpan(0.001, 0.001);
  const region = new mapkit.CoordinateRegion(center, span);

  const [isZoomEventSet, setZoomEventSet] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isMapShowState, setIsMapShowState] = useState<boolean>(false);

  const getProfileCircle = () => {
    const largeCircleStyle = new (mapkit as any).Style({
      strokeColor: theme.palette.red.main,
      strokeOpacity: 0.22,
      fillColor: theme.palette.red.main,
    });

    const coordinate = new mapkit.Coordinate(
      props.location?.lat || 0,
      props.location?.lng || 0,
    );

    addAnotation(coordinate);

    const largeRadius = props.location?.accuracy || 0;

    const largeCircle = new mapkit.CircleOverlay(coordinate, largeRadius);

    largeCircle.style = largeCircleStyle;

    return largeCircle;
  };

  const getCircleRegions = () => {
    if (regions.circleRegions.length === 0) {
      return [];
    }

    const style = new mapkit.Style({
      lineGradient: new mapkit.LineGradient(),
      lineWidth: 2,
      strokeColor: theme.palette.secondary.main,
      fillColor: theme.palette.secondary.main,
      fillOpacity: 0.17,
    });

    const circles = regions.circleRegions.map((region: SafeZone) => {
      const coordinate = new mapkit.Coordinate(region.lat, region.lng);
      const circle = new mapkit.CircleOverlay(coordinate, region.radius);

      circle.style = style;

      addAnotation(coordinate, region.label);

      return circle;
    });

    return circles;
  };

  const getPathRegions = () => {
    if (regions.pathRegions.length === 0) {
      return [];
    }

    const basePolylineStyle = {
      strokeColor: theme.palette.secondary.main,
    };

    const smallPolylineStyle = new (mapkit as any).Style({
      ...basePolylineStyle,
      lineWidth: 3,
      lineDash: [20, 8],
    });

    const largePolylineStyle = new (mapkit as any).Style({
      ...basePolylineStyle,
      lineWidth: getPixelsFromZoomLevelAndRadius(18, region.radius),
      strokeOpacity: 0.3,
      fillColor: theme.palette.secondary.main,
    });

    const paths = regions.pathRegions
      .map((region: SafeZone) => {
        const points = region.geofence.map(
          (point) => new mapkit.Coordinate(point[0], point[1]),
        );

        const smallPolyline = new mapkit.PolylineOverlay(points, {
          style: smallPolylineStyle,
        });
        const largePolyline = new mapkit.PolylineOverlay(points, {
          style: largePolylineStyle,
          data: { radius: region.radius },
        });

        const length = region.geofence.length - 1;

        addAnotation(
          new mapkit.Coordinate(
            (region.geofence[0][0] + region.geofence[length][0]) / 2,
            (region.geofence[0][1] + region.geofence[length][1]) / 2,
          ),
          region.label,
        );

        return [smallPolyline, largePolyline];
      })
      .reduceRight((accumulator, currentValue) =>
        accumulator.concat(currentValue),
      );

    return paths;
  };

  const getPolygonRegions = () => {
    if (regions.polygonRegions.length === 0) {
      return [];
    }

    const style = new mapkit.Style({
      lineGradient: new mapkit.LineGradient(),
      strokeColor: theme.palette.secondary.main,
      fillColor: theme.palette.secondary.main,
      lineWidth: 3,
    });

    const polygons = regions.polygonRegions.map((region: SafeZone) => {
      const points = region.geofence.map(
        (point) => new mapkit.Coordinate(point[0], point[1]),
      );
      const rectangle = new mapkit.PolygonOverlay(points, { style });

      if (region.shapeType === 2) {
        const regionPoints: Point[] = region.geofence.map((item) => ({
          x: item[0],
          y: item[1],
        }));
        const regionObj = new Region(regionPoints);

        addAnotation(
          new mapkit.Coordinate(regionObj.centroid.x, regionObj.centroid.y),
          region.label,
        );
      } else {
        addAnotation(
          new mapkit.Coordinate(region.lat, region.lng),
          region.label,
        );
      }

      return rectangle;
    });

    return polygons;
  };

  const addAnotation = (coordinate: mapkit.Coordinate, label?: string) => {
    const factory = (
      _coordinate: mapkit.Coordinate,
      options: mapkit.AnnotationConstructorOptions,
    ) => {
      const div = document.createElement('div');

      div.textContent = options.title || null;
      div.className = label ? 'zoneAnnotation' : 'circleAnnotation';

      return div;
    };

    const options = {
      title: label,
      calloutEnabled: false,
    };

    const annotation = new mapkit.Annotation(coordinate, factory, options);

    if (map) {
      map.addAnnotation(annotation);
    }
  };

  const handleCenterProfileButtonClick = () => {
    if (map) {
      map.setRegionAnimated(region, true);
    }
  };

  useEffect(() => {
    if (isLoadingState && props.isRegionsLoading) {
      setTimeout(() => {
        setIsLoadingState(() => false);
      }, 2500);
    }

    if (!props.isRegionsLoading && !isMapShowState) {
      if (map) {
        map.destroy();
      }

      map = initMap(region);
      setIsMapShowState(true);
    }
  }, [props.isRegionsLoading, isLoadingState, isMapShowState]);

  useEffect(() => {
    if (map) {
      map.overlays = [];

      map.annotations = [];

      map.setRegionAnimated(region, true);

      map.addOverlay(getProfileCircle());
      map.addOverlays(getCircleRegions());
      map.addOverlays(getPolygonRegions());
      map.addOverlays(getPathRegions());

      if (!isZoomEventSet) {
        map.addEventListener('zoom-end', (e) => handleMapZoomEvent(e));

        setZoomEventSet(true);
      }
    }

    return function cleanup() {
      map?.removeEventListener('zoom-end', (_t, e) => handleMapZoomEvent(e));
      setZoomEventSet(false);
    };
  }, [props.location, map]);

  useEffect(() => {
    if (map && props.location) {
      map.setRegionAnimated(region, true);
    }
  }, [props.location]);

  return (
    <MapView
      patientName={props.patientName}
      onCenterProfileButtonClick={handleCenterProfileButtonClick}
      isLoading={props.isLoading}
      lastRefresh={props.location?.lastUpdate}
      height={props.height || 250}
      width={props.width || 350}
      disabled={props.disabled}
      trackingButtonProps={props.trackingButtonProps}
      hideBcControls={props.hideBcControls}
    />
  );
};

Map.displayName = 'Map';

export default Map;
