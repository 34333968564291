import { Box, LinearProgress, Typography } from '@mui/material';

export const ReportsLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        gap: 2,
        px: 4,
        py: 4,
        width: 'fit-content',
      }}
    >
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: 500 }}>
        Generating your report, this might take a while...
      </Typography>
      <LinearProgress sx={{ width: '100%' }} />
    </Box>
  );
};
