import {
  Content,
  Root,
  Wrapper,
  ButtonsWrapper,
  Form,
} from './RecipientSetEditor.styled';
import General from './General';
import Schedule from './Schedule';
import Recipients from './Recipients';
import { FormProvider } from 'react-hook-form';
import { ReactNode, Ref, forwardRef } from 'react';
import SaveButton from './SaveButton';
import { Level } from './Hierarchy';
import { useEditRecipientsSet } from 'hooks/use-edit-recipients-set';
import { CombinedRecipientManager } from 'store/reducers/alerter/alerter.slice';
import { AlerterRecipientManager } from 'types/alerters';
import MakeCopyButton from './MakeCopyButton';
import { caregiverRole } from 'services/caregiver-role';

export interface EditRecipientSetForm {
  name: string;
  days: Day[];
  startTime: number | null;
  endTime: number | null;
  isOutsideRange: boolean;
  count: number;
  spacing: number;
  recipients: Level[];
}

export type RecipientSetEditorProps = {
  children?: ReactNode;
  hideCopy?: boolean;
  defaultValues: CombinedRecipientManager | AlerterRecipientManager;
  withScheduler?: boolean;
  submitFormRef: Ref<{ submitForm: () => void; isError: boolean }>;
} & (
  | {
      children?: ReactNode;
      defaultValues: CombinedRecipientManager;
      withScheduler?: true;
      submitFormRef: Ref<{ submitForm: () => void; isError: boolean }>;
    }
  | {
      children?: ReactNode;
      defaultValues: AlerterRecipientManager;
      withScheduler?: false;
      submitFormRef: Ref<{ submitForm: () => void; isError: boolean }>;
    }
);

const RecipientSetEditor = forwardRef<HTMLDivElement, RecipientSetEditorProps>(
  (
    { children, submitFormRef, defaultValues, withScheduler, hideCopy },
    ref,
  ) => {
    const { formMethods, handleSubmit } = useEditRecipientsSet(
      defaultValues,
      submitFormRef,
      !!withScheduler,
    );

    const readOnly = caregiverRole.value === 'viewer';

    return (
      <Root ref={ref}>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit}>
            <Wrapper
              sx={{
                pt: 3,
                px: { xxs: 1, newMd: 2 },
                flexGrow: 1,
              }}
            >
              <General
                defaultTitle={defaultValues.name ?? 'Untitled'}
                readOnly={readOnly}
                usedIn={defaultValues.alerters?.map(
                  (alert) => alert.name ?? 'Unknown',
                )}
              />
              <Content
                sx={{
                  px: { xxs: 1, xs: 2 },
                }}
              >
                {withScheduler && (
                  <Schedule
                    defaultDays={defaultValues.days}
                    defaultStartTime={defaultValues.startTime}
                    defaultEndTime={defaultValues.endTime}
                    readOnly={readOnly}
                  />
                )}
                <Recipients
                  defaultSets={defaultValues.sets}
                  defaultCount={defaultValues.attemptCount ?? 1}
                  defaultSpacing={defaultValues.attemptSpacing ?? 120}
                  readOnly={readOnly}
                />
              </Content>
              {children}
            </Wrapper>
            <ButtonsWrapper>
              {!hideCopy && (
                <MakeCopyButton
                  disabled={readOnly}
                  recipientId={defaultValues.id}
                />
              )}
              <SaveButton
                disabled={!formMethods.formState.isDirty || readOnly}
              />
            </ButtonsWrapper>
          </Form>
        </FormProvider>
      </Root>
    );
  },
);

export default RecipientSetEditor;
