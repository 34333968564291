import { yupResolver } from '@hookform/resolvers/yup';
import { EditRecipientSetForm } from 'components/_extra/Alerter';
import { Ref, useEffect, useImperativeHandle } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import {
  useAddAlerterRecipientToSetMutation,
  useAddRecipientSetToManagerMutation,
  useRemoveAlerterRecipientFromSetMutation,
  useRemoveRecipientSetFromManagerMutation,
  useUpdateAlerterRecipientManagerMutation,
  useUpdateAlerterSchedulerMutation,
} from 'store/api/alerters.api';
import {
  CombinedRecipientManager,
  setRecipientSetAsModified,
  setRecipientSetAsUntouched,
} from 'store/reducers/alerter/alerter.slice';
import * as yup from 'yup';
import {
  Level,
  Recipient,
} from 'components/_extra/Alerter/RecipientSetEditor/Hierarchy';
import { AlerterRecipientManager, PatternType } from 'types/alerters';
import { getUTCInterval } from 'utils/timezones';

export const recipientMangerSchema = yup.object({
  name: yup.string().required('Title is required.').min(1),
  count: yup.number().min(1).max(5).required('Attempts count is required.'),
  spacing: yup.number().min(1).max(15).required('Time interval is required.'),
  days: yup
    .array()
    .typeError('At least one channel must be selected.')
    .min(1, 'At least one channel must be selected.'),
});

export const useEditRecipientsSet = (
  defaultValues: CombinedRecipientManager | AlerterRecipientManager,
  ref: Ref<{ submitForm: () => void; isError: boolean }>,
  isReminder: boolean,
  onClose?: () => void,
) => {
  const formMethods = useForm<EditRecipientSetForm>({
    resolver: yupResolver(recipientMangerSchema),
    reValidateMode: 'onSubmit',
  });

  const [addRecipient] = useAddAlerterRecipientToSetMutation();
  const [removeRecipient] = useRemoveAlerterRecipientFromSetMutation();
  const [addSetToManager] = useAddRecipientSetToManagerMutation();
  const [removeSetFromManager] = useRemoveRecipientSetFromManagerMutation();
  const [updateScheduler, schedulerState] = useUpdateAlerterSchedulerMutation();
  const [updateRecipientManager, recipientManagerState] =
    useUpdateAlerterRecipientManagerMutation();

  const { isDirty } = useFormState({ control: formMethods.control });

  const dispatch = useAppDispatch();

  useEffect(() => {
    formMethods.reset({
      name: defaultValues.name ?? '',
      count: defaultValues.attemptCount,
      spacing: defaultValues.attemptSpacing,
      recipients: defaultValues.sets.map((set) => ({
        priority: set.priority,
        recipients: set.recipients.map((recipient) => ({
          id: recipient.id,
          type: recipient.recipientType ?? recipient.patternType,
        })),
      })),
    });
  }, []);

  useEffect(() => {
    if (isDirty) {
      dispatch(setRecipientSetAsModified());
    } else {
      dispatch(setRecipientSetAsUntouched());
    }
  }, [isDirty]);

  const getRecipientsToUpdate = (newSets: Level[]) => {
    const newLength = newSets.length;
    const oldSets = defaultValues.sets.map((set, index) => {
      return {
        recipients: set.recipients.map((recipient) => {
          const type: PatternType | 'User' | 'PatientProfile' =
            recipient.recipientType ?? recipient.patternType;
          return {
            id: recipient.id,
            type,
          };
        }),
        priority: (index + 1) * 10,
        id: set.id,
      };
    });

    const setsToDelete = oldSets
      .filter(
        (oldSet) =>
          newSets.findIndex((set) => set.id === oldSet.id && !!set.id) === -1,
      )
      .map(({ id }) => id);

    const filteredOldSets = oldSets.filter(
      ({ id }) => !setsToDelete.includes(id),
    );

    let toUpdate = filteredOldSets.reduce((previous, current, index) => {
      const value: {
        priority?: number;
        recipients: Recipient[];
        toRemove: Recipient[];
      } = {
        recipients: [],
        toRemove: [],
      };
      if (current.priority !== newSets[index]?.priority) {
        value.priority = current.priority;
      }
      if (newSets[index]) {
        const newRecipients = newSets[index].recipients.filter(
          (rec) =>
            filteredOldSets[index].recipients.findIndex(
              (oldRec) => oldRec.id === rec.id && oldRec.type === rec.type,
            ) === -1,
        );
        if (newRecipients.length > 0) {
          value.recipients = newRecipients;
        }
        const removedRecipients = current.recipients.filter(
          (rec) =>
            newSets[index].recipients.findIndex(
              (newRec) => newRec.id === rec.id && newRec.type === rec.type,
            ) === -1,
        );
        if (removedRecipients.length > 0) {
          value.toRemove = removedRecipients;
        }
      }

      return [...previous, value];
    }, [] as Partial<Level>[]);

    if (oldSets.length < newSets.length) {
      toUpdate = toUpdate.concat(newSets.splice(oldSets.length));
    }

    return {
      toUpdate,
      originalLength: oldSets.length,
      newLength,
      setsToDelete,
    };
  };

  const updateRecipients = (recipients: Level[]) => {
    const { originalLength, newLength, toUpdate, setsToDelete } =
      getRecipientsToUpdate(recipients);

    setsToDelete.forEach((id) => {
      removeSetFromManager({
        setId: id,
        managerId: defaultValues.id,
      });
    });

    for (let i = 0; i < newLength; i++) {
      if (i < originalLength) {
        toUpdate[i].toRemove?.forEach((rec) => {
          removeRecipient({
            recipientId: rec.id,
            setId: defaultValues.sets[i].id,
          });
        });

        toUpdate[i].recipients?.forEach((rec) => {
          addRecipient({
            recipientId: rec.id,
            recipientType: rec.type,
            setId: defaultValues.sets[i].id,
          });
        });
      } else {
        addSetToManager({
          recipientManagerId: defaultValues.id,
          alerterRecipients: [],
          priority: toUpdate[i].priority,
        })
          .unwrap()
          .then(({ result }) => {
            toUpdate[i].recipients?.forEach(async ({ id, type }) => {
              await addRecipient({
                setId: result.id,
                recipientType: type,
                recipientId: id,
              });
            });
          });
      }
    }
  };

  const onSubmit = (data: EditRecipientSetForm) => {
    if (
      data.recipients.findIndex(({ recipients }) => recipients.length === 0) !==
      -1
    ) {
      formMethods.setError('recipients', {
        type: 'required',
        message: 'Recipients field is required.',
      });
      return;
    }

    updateRecipients(data.recipients);

    if (
      'schedulerId' in defaultValues &&
      typeof data.startTime === 'number' &&
      typeof data.endTime === 'number'
    ) {
      updateScheduler({
        id: defaultValues.schedulerId,
        serialized_schedule: getUTCInterval(
          data.startTime,
          data.endTime,
          data.days,
        ),
        invert_time_range: data.isOutsideRange,
      });
    }
    updateRecipientManager({
      id: defaultValues.id,
      attemptCount: data.count,
      attemptSpacing: data.spacing * 60,
      name: data.name,
    });
    onClose?.();

    formMethods.reset(data);
  };

  useImperativeHandle(ref, () => ({
    submitForm() {
      if (formMethods.getValues().name.length > 0) {
        onSubmit(formMethods.getValues());
        return;
      }
      formMethods.setError('name', { message: 'Title is required.' });
    },
    isError: Object.keys(formMethods.formState.errors).length > 0,
  }));

  useEffect(() => {
    if (
      (schedulerState.isError && !schedulerState.isLoading) ||
      (recipientManagerState.isError && !recipientManagerState.isLoading)
    ) {
      toast.error('Something went wrong');
      return;
    }

    dispatch(setRecipientSetAsUntouched());
    if (
      (schedulerState.isSuccess && !schedulerState.isLoading) ||
      (recipientManagerState.isSuccess && !recipientManagerState.isLoading)
    ) {
      toast.success('Recipient set was updated successfully');
      return;
    }
  }, [recipientManagerState.isLoading]);

  return {
    formMethods,
    handleSubmit: formMethods.handleSubmit(onSubmit),
  };
};
