import { alpha, Box, Typography } from '@mui/material';
import { useMemo } from 'react';

type Item = {
  name: string;
  color: string;
  value: number;
};

type Props = {
  items: [Item, Item];
};

export const ConcurrentView = ({ items }: Props) => {
  const { total, percentages } = useMemo(() => {
    const total = items.reduce((acc, item) => acc + item.value, 0);
    if (total === 0) return { percentages: [0, 0], total };
    return {
      total,
      percentages: items.map(({ value }) => (value / total) * 100),
    };
  }, [items]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {items.map((item, index) => (
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            flexDirection={index === 0 ? 'row' : 'row-reverse'}
            sx={{ color: alpha('#000', 0.6) }}
            fontSize={14}
            key={item.name + item.color}
          >
            <Box
              sx={({ spacing }) => ({
                bgcolor: item.color,
                width: spacing(1),
                height: spacing(1),
                borderRadius: '50%',
              })}
            ></Box>
            {item.name}
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {percentages.map((item) => (
          <Typography
            key={item + Math.random()}
            sx={{
              fontSize: 20,
              lineHeight: '32px',
              fontWeight: 500,
              color: () => alpha('#000', 0.8),
            }}
          >
            {total === 0 ? 'N/A' : `${item.toFixed(0)}%`}
          </Typography>
        ))}
      </Box>
      <Box sx={{ display: 'flex', gap: 0.5 }}>
        {total === 0 ? (
          <Box
            sx={({ spacing, palette }) => ({
              height: spacing(1),
              bgcolor: alpha(palette.primary.main, 0.2),
              borderRadius: spacing(1),
              width: '100%',
            })}
          ></Box>
        ) : (
          items.map((item, index) =>
            percentages[index] === 0 ? null : (
              <Box
                key={item.value + index}
                sx={({ spacing }) => ({
                  height: spacing(1),
                  bgcolor: item.color,
                  borderRadius: spacing(1),
                  width: `${percentages[index]}%`,
                })}
              ></Box>
            ),
          )
        )}
      </Box>
    </Box>
  );
};
