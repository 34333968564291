import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetProfileAvailableReportsQuery,
  useGetProfileReportsQuery,
} from 'store/api/profiles.api';
import { AlerterEventsWidgets } from 'types/alerters';
import { DateRange } from 'types/date-time';
import { ProfileReportsIdentifier } from 'types/patient';
import { getReportsWidgets } from 'utils/get-reports-widgets';

const alerterEvents: AlerterEventsWidgets[] = [
  'safe_zone',
  'help_requested',
  'fall',
  'motion_disorder',
];

const getCustomFieldValue = (field: string) => {
  if (field === 'limit') {
    return 5;
  }

  if (field === 'widget_targets') return alerterEvents;
};

const getTo = (date: Dayjs | null) => {
  if (!date) return;
  const newDate = new Date(date.format('YYYY-MM-DD'));
  newDate.setHours(23, 59, 59, 999);
  return newDate.toISOString();
};

const getFrom = (date: Dayjs | null) => {
  if (!date) return;
  const newDate = new Date(date.format('YYYY-MM-DD'));
  newDate.setHours(0, 0, 0, 0);
  return newDate.toISOString();
};

export const useProfileReports = (dateRange: DateRange) => {
  const { profileId } = useParams<{ profileId: string }>();

  const { data, isLoading } = useGetProfileAvailableReportsQuery({
    profileId: Number(profileId),
    from: dateRange[0]?.toISOString(),
    to: dateRange[1]?.toISOString(),
  });

  const statsList = useMemo(() => {
    if (!data) return [];

    return data.results.reduce((acc, item) => {
      if (typeof item === 'string') {
        return [...acc, item];
      }

      let newElement: Record<string, unknown> = {};

      Object.entries(item).map(([key, value]) => {
        if (Array.isArray(value)) {
          newElement = {
            ...newElement,
            [key]: {
              [value[0]]: getCustomFieldValue(value[0]),
            },
          };
        }
      });

      return [...acc, newElement];
    }, [] as ProfileReportsIdentifier[]);
  }, [data]);

  const { data: reports, isFetching } = useGetProfileReportsQuery(
    {
      profileId: Number(profileId),
      from: getFrom(dateRange[0]),
      to: getTo(dateRange[1]),
      statsList,
    },
    {
      skip: statsList.length === 0,
    },
  );

  return {
    data: reports,
    widgets: getReportsWidgets(reports?.results),
    isLoading: isLoading || isFetching,
  };
};
