import { Box, Paper, Skeleton, SxProps, Typography } from '@mui/material';

export interface WidgetBaseProps {
  title?: string;
  children?: React.ReactNode;
  lastUpdate?: string | number;
  headerContent?: React.ReactNode;
  padding?: string;
  isLoading?: boolean;
  skeletonSx?: SxProps;
}

export const CardBase = ({
  title,
  children,
  headerContent,
  isLoading,
}: WidgetBaseProps) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" height="100%" />;
  }

  return (
    <Paper
      elevation={2}
      sx={{
        p: ({ spacing }) => spacing(2),
        height: '100%',
      }}
    >
      {title && (
        <Box display="flex">
          <Typography flexGrow={1} variant="subtitle2">
            {title}
          </Typography>
          {headerContent}
        </Box>
      )}
      <Box py={2} display="flex" flexDirection="column" gap={2}>
        {children}
      </Box>
    </Paper>
  );
};
